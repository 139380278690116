/* JS */

/* react */
import React, { useState } from 'react';
/* third part components */

/* custom components */
import CaseStudyPage from './CaseStudyPage';

import './case-study.css';

var gsap;
var detect = require('is-client');
if (detect()) {
	gsap = require('gsap');
	let sctr = require('gsap/ScrollTrigger');
	gsap.gsap.registerPlugin(sctr);
}

const TEDxPage = props => {
	return (
		<CaseStudyPage
			title="Branding, Progettazione visiva, Progettazione web - TEDxLuiss"
			relPath="tedxluiss"
			description="TED ha lanciato un programma di eventi locali, chiamato TEDx, che si propone di far vivere localmente un’esperienza simile a quella che si vive partecipando a una conferenza TED."
		>
		</CaseStudyPage>
	);
};

export default TEDxPage;
